// TODO: This CSS will likely get split up and moved into invidual components .module.scss files

.spokenote-sticker-visual {
  height: 200px;
  width: 200px;
  margin: 0 auto 10px auto;
  position: relative;
}

.spokenote-video-visual {
  margin-bottom: 20px;
}

@keyframes scanning {
  0% { transform: scale(1); }
  20% { transform: scale(1.1); }
  40% { transform: scale(1); }
  60% { transform: scale(1.1); opacity: 1;}
  80% { transform: scale(1); }
  100% { transform: scale(1); opacity: 0;}
}

#spokenote-sticker-scanning {
  width: 100%;
  max-width: 200px;
  position: absolute;
  top: 0;
  left: 0;
  animation-name: scanning;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;

  path {
    fill: none;
    stroke: $green_light;
    stroke-width: 4;
    stroke-linejoin: round;
  }
}

// #spokenote-sticker {
//   width: 100%;
//   max-width: 200px;
//   position: absolute;
//   top: 0;
//   left: 0;

//   .st0 {
//     fill: #E8EBEE;
//     stroke: $primary;
//     stroke-width: 3;
//     stroke-linecap: round;
//     stroke-linejoin: round;
//     stroke-miterlimit: 10;
//   }

//   .st1 {
//     fill: $white;
//     stroke: $primary;
//     stroke-width: 3;
//     stroke-linecap: round;
//     stroke-linejoin: round;
//     stroke-miterlimit: 10;
//   }

//   .st2{
//     fill: $primary;
//   }
// }

@keyframes qrData {
  0% { opacity: 1; }
  5% { opacity: 1; }
  10% { opacity: 0; }
  15% { opacity: 1; }
  100% { opacity: 1; }
}

#spokenote-sticker-animated {
  width: 100%;
  max-width: 200px;
  position: absolute;
  top: 0;
  left: 0;

  .st0{
    fill: #E7EAED;
    stroke: $primary;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .st1{
    fill: $white;
    stroke: $primary;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .st2{
    fill: $primary;
    stroke: $primary;
    stroke-width: .25;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
}

.animated {

  #spokenote-sticker-animated {

    g {
      rect{
        animation-name: qrData;
        animation-duration: 4s;
        animation-iteration-count: infinite;
        animation-fill-mode: forwards;
      }

      rect:nth-child(10n+1) {

        animation-delay: 0s;
      }

      rect:nth-child(10n+2) {
        animation-delay: .5s;
      }

      rect:nth-child(10n+3) {
        animation-delay: 1s;
      }

      rect:nth-child(10n+4) {
        animation-delay: 1.5s;
      }

      rect:nth-child(10n+5) {
        animation-delay: 2s;
      }

      rect:nth-child(10n+6) {
        animation-delay: 2.5s;
      }

      rect:nth-child(10n+7) {
        animation-delay: 3s;
      }
      rect:nth-child(10n+8) {
        animation-delay: 3.5s;
      }

      rect:nth-child(10n+9) {
        animation-delay: 4s;
      }
    }
  }
}

.error {

  #spokenote-sticker-animated {

    .st0{
      fill:#FDE7EF;
      stroke:$negative;
      stroke-width:3;
      stroke-linecap:round;
      stroke-linejoin:round;
      stroke-miterlimit:10;
    }

    .st1{
      fill:#FFFFFF;
      stroke:$negative;
      stroke-width:3;
      stroke-linecap:round;
      stroke-linejoin:round;
      stroke-miterlimit:10;
    }

    .st2{
      fill:$negative;
      stroke:$negative;
      stroke-width:.25;
      stroke-linecap:round;
      stroke-linejoin:round;
      stroke-miterlimit:10;
    }
  }

  .spokenote-sticker-visual-title h4 {
    color: $negative;
  }
}

.spokenote-sticker-visual-title {
  margin: 0 auto 40px auto;
  max-width: 400px;
  text-align: center;

  .time-limit {
    background: rgba(0,0,0,.05);
    border-radius: 20px;
    padding: 6px 15px;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 10px;

    div {
      font-size: 14px;
      font-family: 'mazzard-semibold', sans-serif;
      font-weight: normal;
      font-style: normal;
      text-wrap: nowrap;
    }

    svg {
      min-width: 16px;
      max-width: 16px;
      line,
      circle,
      path,
      polyline {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: none;
        stroke: $darkgray;
        stroke-width: 1.5;
        stroke-miterlimit: 10;
        stroke-linecap: round;
      }
    }
  }
}

.instructions-description {
  ol, ul {
    padding-inline-start: 1em;
  }

  li p strong {
    display: block;
  }
}

.post-submission-instructions {
  max-width: 500px;
}

@keyframes load-fade-up {
  0% { transform: translateY(20px); opacity: 0;}
  100% { transform: translateY(0); opacity: 1;}
}

.add-video-options {
  margin: 0 auto 20px auto;
  max-width: 400px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
  position: sticky;
  bottom: 10px;
  padding: 10px;
  margin-left: 50%;
  transform: translateX(-50%);
  // background: $tint;
  // border-radius: 40px;
  width: 100%;
  background: rgba(255,255,255,.5);
  backdrop-filter: blur(4px);

  @media (min-width: 640px) {
    flex-direction: row;
  }

  .btn {
    margin-bottom: 0;

    span {
      text-wrap: nowrap;
    }
  }

  .btn-content {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none;
  }

  .record {
    // transform: translateY(20px);
    // opacity: 0;
    // animation-name: load-fade-up;
    // animation-duration: 2s;
    // animation-fill-mode: forwards;
    // animation-delay: 1s;
  }

  .upload {

    button {
      padding: 0;

      label {
        padding: 16px 24px;
      }
    }
    // transform: translateY(20px);
    // opacity: 0;
    // animation-name: load-fade-up;
    // animation-duration: 2s;
    // animation-fill-mode: forwards;
    // animation-delay: 1.25s;
      // @media (min-width: 640px) {
      //   animation-delay: 1s;
      // }
  }
}

.add-video-defaults {
  margin: 0 auto;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  @media (min-width: 640px) {
    flex-direction: row;
  }

  .btn {
    margin-bottom: 0;
  }
}

.icon-record {
  width: 26px;
  min-width: 26px;

  path,
  line,
  polyline,
  circle {
    fill: none;
    stroke: $white;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
  }
}

.icon-check {
  width: 26px;

  path,
  circle,
  polyline {
    fill: none;
    stroke: $white;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
  }
}

.btn-primary-outline {
  .icon-record {
    width: 26px;
    min-width: 26px;

    path,
    circle {
      fill: none;
      stroke: $primary;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2px;
    }
  }
}

.video-preview-label {
  position: absolute;
  width: calc(100% - 20px);
  text-align: center;
  top: 10px;
  left: 10px;
  border-radius: 6px;
  padding: 8px 12px;
  color: $white;
  background: rgba(0,0,0,.3);
  font-family: 'mazzard-bold', sans-serif;
  font-weight: normal;
  font-style: normal;
  z-index: 10;
}

.video-actions-container {
  margin-top: 30px;
  position: relative;
  position: sticky;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: sticky;
  bottom: 10px;
  padding: 10px;
  margin-left: 50%;
  transform: translateX(-50%);
  background: $tint;
  border-radius: 40px;
  z-index: 10;

  .btn {
    margin-bottom: 0;
  }

  &:empty {
    margin-top: 0px;
    display: none;
  }
}

.video-wrapper {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  border-radius: 12px;

  &.error {

    // &:before {
    //   content: '';
    //   display: block;
    //   position: absolute;
    //   inset: 0;
    //   z-index: 10;
    //   background: $red;
    //   opacity: .3;
    //   touch-action: none;
    //   pointer-events: none;
    // }

    &:after {
      content: '';
      display: block;
      position: absolute;
      inset: 0;
      z-index: 10;
      box-shadow: inset 0 0 0 3px $red;
      border-radius: inherit;
      touch-action: none;
      pointer-events: none;
    }
  }
}

video {
  width: 100% !important;
  min-width: 100%;
  height: auto;
  border-radius: 12px;
  overflow: hidden;
  background: $mediumgray;
  transition: .15s linear;
}

#btn-record-wrapper {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 70px;
  height: 70px;
  background: rgba(0,0,0,0.7);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .15s linear;

  .btn-record {
    z-index: 10;
    width: 50px;
    height: 50px;
    background: $red;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background .5s linear, transform .15s linear;
    text-indent: -9999px;
    overflow: hidden;

    .btn-record-icon {
      width: 10px;
      height: 10px;
      background: $white;
      border-radius: 50%;
      transition: .5s linear;
    }
  }

  &:hover {

    .btn-record {
      transform: scale(1.1);
    }
  }
}

.start-recording-prompt {
  position: absolute;
  color: $white;
  bottom: 110px;
  left: 50%;
  text-align: center;
  padding: 0 20px;
  width: 100%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  line-height: 1em;
  font-family: 'mazzard-bold', sans-serif;
  font-weight: normal;
  font-style: normal;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  transition: .15s linear;
}

.counting-down,
.recording {
  .start-recording-prompt {
    opacity: 0;
    pointer-events: none;
    touch-action: none;
  }
}

.video-recording-timer {
  bottom: 100px;
  left: 50%;
  transform: translate(-50%, -100%);
  font-size: 16px;
  line-height: 1em;
  color: $white;
  text-align: left;
  width: 95px;
  border-radius: 6px;
  font-family: 'mazzard-bold', sans-serif;
  font-weight: normal;
  font-style: normal;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: 0.25s linear;
  z-index: 10;
  position: absolute;
  display: flex;
  justify-content: space-between;
  padding: 4px 6px;

  @keyframes final-countdown {
    0% {
      background: rgba(225,36,75,1);
    }
    50% {
      background: rgba(225,36,75,.3);
    }
    100% {
      background: rgba(225,36,75,1);
    }
  }

  &.time-warning {
    background: rgba(225,36,75,1);
    animation: final-countdown 1s linear infinite;
  }

  .max-time {
    opacity: .5;
  }
}

.recording {

  #btn-record-wrapper {

    .btn-record {
      background: #ffffff;

      .btn-record-icon {
        width: 20px;
        height: 20px;
        background: $red;
        border-radius: 4px;
        transition: .15s linear;
      }
    }
  }

  .video-recording-timer {
    opacity: 1;
  }
}

.record-video-countdown {
  position: absolute;
  top: calc(50% - 10px);
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 148px;
  line-height: 1em;
  color: $white;
  font-family: 'mazzard-bold', sans-serif;
  font-weight: normal;
  font-style: normal;
  text-shadow: 1px 1px 4px rgba(0,0,0,.4);
  z-index: 10;
}

.sponsor-banner {
  margin: 30px auto;
  width: 100%;
  max-width: 800px;

  img {
    margin: 0 auto;
    width: 100%;
  }
}

.sn-block {
  margin: 30px auto;
  width: 100%;
  max-width: 800px;
}

.sn-block-video {
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;

  .recorder-mirror {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
}

.sn-block-dynamic-merge-video {
  border-radius: 12px;
  overflow: hidden;

  > div:first-of-type {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 4 / 3;
  }

  button {
    padding: 10px !important;
    background: transparent;
  }
}

.sn-block-cta {
  display: flex;
  justify-content: center;
  bottom: 0;
  background: rgba(255,255,255,.9);
  backdrop-filter: blur(4px);

  .btn {
    margin-bottom: 0;
  }
}

.sn-block-text {

  p {
    text-align: center;
  }
}

.sn-block-image {

  img {
    margin: 0 auto;
    width: 100%;
  }
}

.sn-block-contact-card {

  .contact-card-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding-top: 30px;
    border-radius: 12px;
  }

  .contact-card-image {
    height: 100px;
    width: 100px;
    border-radius: 50px;
    background: $tint;
    overflow: hidden;
    margin-bottom: 5px;
    object-fit:cover
  }

  .contact-card-name {
    font-size: 24px;
    line-height: 1.1em;
    font-family: 'mazzard-bold', sans-serif;
    font-weight: normal;
    font-style: normal;
    text-align: center;
    padding: 0 10px;
  }

  .contact-card-title {
    font-size: 18px;
    line-height: 1.1em;
    font-family: 'mazzard', sans-serif;
    font-weight: normal;
    font-style: normal;
    text-align: center;
    padding: 0 10px;
  }

  .contact-card-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
    border-top: 2px solid rgba(0,0,0,.2);
    width: 100%;
    gap: 2px;

    .contact-card-button {
      padding: 12px 16px;
      flex: 1;
      text-align: center;
      position: relative;
      transition: .25s linear;
      text-decoration: none;

      &:hover {
        background: rgba(255,255,255,.1);
      }

      &:before {
        content: '';
        display: block;
        width: 2px;
        height: 100%;
        position: absolute;
        top: 0;
        left: -2px;
        background: rgba(0,0,0,.2);
      }

      &:first-child:before {
        display: none;
      }
    }
  }
}

.sn-block-link-list {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .list-item {
    display: flex;
    flex-direction: row;
    gap: 15px;

    .list-item-icon {
      width: 30px;
      min-width: 30px;
      height: 30px;

      .link-list-icon {

        .st0 {
          fill: $darkgray;
        }
      }
    }

    .list-item-info {

      .list-item-message {

        .link {

        }
      }

      .list-item-link {
        color: $darkgray;
      }

      .list-item-subtitle {
        font-size: 14px;
        color: $mediumgray;
      }
    }
  }
}

.sn-block-social-bar {

  .social-buttons {
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: center;
    align-items: center;
    width: 100%;

    .social-button {

      svg {
        width: 40px;
        min-width: 40px;

        .st0 {
          fill: $darkgray;
        }
        .st1 {
          fill: $white;
        }
      }
    }
  }
}

.sn-block-vcard {
  display: flex;
  justify-content: center;
}

.upload-progress-bar-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;

  .upload-progress-bar {
    width: 100%;
    max-width: 300px;
    height: 20px;
    background: $white;
    border-radius: 12px;
    box-shadow: 0 0 0px 3px $white, 0 0 0px 5px $secondary;

    @keyframes progress-gradient {
      0% {
        background-position: 70%;
      }
      50% {
        background-position: 0%;
      }
      100% {
        background-position: 70%;
      }
    }

    .progress {
      height: 100%;
      min-width: 28px;
      background: $primary;
      background: linear-gradient(135deg, #6DC9C4 0%, #005677 100%);
      background-size: 150% auto;
      background-position: 70%;
      animation: progress-gradient 2s ease infinite;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .progress-percentage {
        color: $white;
        font-size: 12px;
        font-family: 'mazzard-bold', sans-serif;
        font-weight: normal;
        font-style: normal;
        padding-right: 5px;
      }
    }
  }
}

.optional-video-fields {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  .read-receipt-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
}

.remaining-chars {
  font-size: 12px;

  &.error {
    color: $red;
  }
}

.video-error-message {
  background: $red;
  color: $white;
  padding: 10px 15px;
  max-width: 800px;
  margin: 0 auto 20px auto;
  border-radius: 6px;
  line-height: 1.1em;
}

.alert {
  width: 100%;
  max-width: 400px;
  margin-bottom: 40px;
  background: #efefef;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  align-items: top;
  gap: 5px;
  justify-content: space-between;
  flex-direction: column;

  p {
    margin-bottom: 0;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }

  &.alert-error {
    background: rgba(225, 36, 75,.2);
  }

  &.alert-success {
    background: rgba(0, 188, 129,.2);
  }

  // .alertClose {
  //   cursor: pointer;
  //   height: 20px;
  //   width: 20px;
  //   border-radius: 50%;
  //   color: #ffffff;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   background: #000000;
  //   opacity: .7;
  //   transition: .15s linear;
  //   min-width: 20px;

  //   &:hover {
  //     opacity: 1;
  //   }
  // }
}