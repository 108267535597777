.video-player-icon {
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.4));
  min-width: 24px;

  path,
  circle,
  line,
  polyline,
  polygon {
    fill:none;
    stroke: $white;
    stroke-width: 1.5px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
}
