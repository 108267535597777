.ReactModalPortal {
  z-index: 500;
  position: fixed;
}

.ReactModal__Overlay {
  background: rgba(245,245,245,.8) !important;
  backdrop-filter: blur(4px);
  z-index: 500;
}

.ReactModal__Body--open {
  position: fixed;
}

.ReactModal__Content {
  border-radius: 12px !important;
  border: 0px !important;
  padding: 0px !important;
  z-index: 500 !important;
  box-shadow: 3px 0px 16px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: calc(100% - 40px);
  position: relative;
  min-height: 0;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  inset: 60px 0 0 0 !important;
  transform: none !important;
  width: 100% !important;
  margin: 0 !important;
  min-width: 100% !important;
  
  @media (min-width: 640px) {
    max-width: 600px;
    margin-top: 30px;
    background: red;
    inset: 50% auto auto 50% !important;
    width: auto !important;
    min-width: auto !important;
    margin-top: 30px !important;
    transform: translate(-50%, -50%) !important;
  }
}

.modal-title {
  padding: 10px 20px;
  border-bottom: 1px solid rgba(0,0,0,.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background: green;

  .title {
    font-family: 'mazzard-semibold', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 22px;
    line-height: 1.1em;
  }

  .btn-close {
    font-size: 32px !important;
    border: 0 !important;
    padding: 0;
    margin-bottom: 0;
    box-shadow: none !important;
    height: 40px;
    width: 40px;
    justify-content: flex-end;
    color: $mediumgray;
    background: transparent;

    &:hover {
      color: $primary;
    }
  }
}

.modal-body {
  overflow: scroll;
  padding: 20px;
  flex-grow: 1;
  overflow: auto;
  min-height: 0; /* for Firefox */

  canvas {
    max-width: 100%;
  }
}

.modal-actions {
  margin: 0 auto;
  max-width: 400px;
  border-top: 1px solid rgba(0,0,0,.1);
  padding: 10px;
  min-width: calc(100% + 40px);
  min-height: 70px;
  margin-left: -20px; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  @media (min-width: 640px) {
    flex-direction: row;
  }

  button,
  .btn {
    margin-bottom: 0;
  }
}