.form-field {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 20px;

  &.center-content {
    
    label {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  button,
  input[type="submit"],
  input[type="reset"],
  input[type="button"] {
    align-self: start;
    margin-bottom: 0;
  }
}

label {
  color: $darkgray;
  font-family: 'mazzard-semibold', sans-serif;
  font-weight: normal;
  font-style: normal;
}

.helper-text {
  font-size: 12px;
  color: $mediumgray;
  font-family: 'mazzard', sans-serif;
  font-weight: normal;
  font-style: normal;
}

input,
textarea {
  border: 2px solid $gray_light;
  box-shadow: none;
  border-radius: 6px;
  box-shadow: none;
  outline: none;
  transition: .15s linear;
  padding: 8px 0 8px 10px;
  width: 100%;
  max-width: 340px;
  font-family: 'mazzard', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  color: $darkgray;

  &:hover {
    border: 2px solid $secondary;
  }

  &:focus {
    border: 2px solid $secondary;
    box-shadow: 0 0 0 1px $secondary;
  }

  &:disabled,
  &[disabled] {
    background: $gray_light;
    color: #efefef;
    pointer-events: none;
    touch-action: none;
    cursor: not-allowed;

    &:hover {
      border: 2px solid $gray_light;
    }
  }
}

input[type="submit"],
input[type="reset"],
input[type="button"] {

  &:hover {
    border: 0;
  }
}

.form-control {
  display: flex;
  gap: 10px;
}


.form-control--disabled {
  color: var(--form-control-disabled);
  cursor: not-allowed;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: $white;
  margin: 0;
  padding: 0 0 0 2px;
  width: 30px;
  min-width: 30px;
  height: 30px;
  border: 2px solid $gray_light;
  border-radius: 6px;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  cursor: pointer;

  &::before {
    content: "";
    width: 20px;
    height: 20px;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: center center;
    transition: .15s transform ease-in-out;
    background-color: $secondary;
  }

  &:focus {
    border: 2px solid $secondary;
    box-shadow: 0 0 0 1px $secondary;
  }

  &:hover {
    border: 2px solid $secondary;
  }

  &:checked::before {
    transform: scale(1);
  }

  &:hover:focus {
    border: 2px solid $secondary;
    box-shadow: 0 0 0 1px $secondary;
  }
  
  &:disabled {
    color: #888888;
    cursor: not-allowed;
  }
}