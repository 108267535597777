@font-face {
  font-family: mazzard-extralight;
  src: url(../fonts/mazzard/MazzardSoftM-ExtraLight.woff);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: mazzard-light;
  src: url(../fonts/mazzard/MazzardSoftM-Light.woff);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: mazzard-thin;
  src: url(../fonts/mazzard/MazzardSoftM-Thin.woff);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: mazzard;
  src: url(../fonts/mazzard/MazzardSoftM-Regular.woff);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: mazzard-medium;
  src: url(../fonts/mazzard/MazzardSoftM-Medium.woff);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: mazzard-semibold;
  src: url(../fonts/mazzard/MazzardSoftM-SemiBold.woff);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: mazzard-bold;
  src: url(../fonts/mazzard/MazzardSoftM-Bold.woff);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: mazzard-extrabold;
  src: url(../fonts/mazzard/MazzardSoftM-ExtraBold.woff);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: mazzard-black;
  src: url(../fonts/mazzard/MazzardSoftM-Black.woff);
  font-weight: normal;
  font-style: normal;
}


html, body {
  font-family: 'mazzard', sans-serif;
  font-weight: normal;
  font-style: normal;
  position: relative;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  font-weight: inherit;
}

h1.heading-hero,
h1.heading-hero-responsive,
h2.heading-hero-responsive {
  font-size: 60px;
  line-height: 1.1;
  font-family: 'mazzard', sans-serif;
  font-weight: normal;
  font-style: normal;
  margin-bottom: 30px;
}

@media screen and (max-width: 420px) {
  h1.heading-hero-responsive,
  h2.heading-hero-responsive {
    font-size: 42px !important;
  }
}

h1,
.h1 {
  font-size: 48px;
  line-height: 1.1em;
  letter-spacing: -1px;
  font-family: 'mazzard-bold', sans-serif;
  font-weight: normal;
  font-style: normal;
  margin-bottom: 15px;
  color: $primary;
}

@media screen and (min-width: 960px) {
  h1,
  .h1 {
    font-size: 64px;
  }
}

h2,
.h2 {
  font-size: 20px;
  line-height: 1.3em;
  letter-spacing: -1px;
  font-family: 'mazzard', sans-serif;
  font-weight: normal;
  font-style: normal;
  margin-bottom: 15px;
  color: $primary;
}

@media screen and (min-width: 960px) {
  h2,
  .h2 {
    font-size: 24px;
    line-height: 1.3em;
  }
}

h3,
.h3 {
  font-size: 28px;
  line-height: 1.3em;
  font-family: 'mazzard', sans-serif;
  font-weight: normal;
  font-style: normal;
  margin-bottom: 15px;
  color: $darkgray;
}

h4,
.h4 {
  font-size: 20px;
  line-height: 1.2em;
  font-family: 'mazzard-bold', sans-serif;
  font-weight: normal;
  font-style: normal;
  margin-bottom: 15px;
  color: $secondary;
}

h5,
.h5 {
  font-size: 20px;
  line-height: 27px;
  font-family: 'mazzard', sans-serif;
  text-transform: uppercase;
  color: $mediumgray;
  font-weight: normal;
  font-style: normal;
  margin-bottom: 15px;
}

h6,
.h6 {
  font-size: 16px;
  line-height: 22px;
  font-family: 'mazzard', sans-serif;
  font-weight: normal;
  font-style: normal;
  margin-bottom: 15px;
}

p,
.p {
  font-size:18px;
  line-height:26px;
  font-family: 'mazzard', sans-serif;
  font-weight: normal;
  font-style: normal;
  margin-bottom: 15px;
}

strong,
b {
  font-family: 'mazzard-bold', sans-serif;
  font-weight: normal;
  font-style: normal;
}

p.small,
.p-small {
  font-size:16px;
  line-height:22px;
}

.center {
  text-align: center;
}

.center-content {
  margin: 0 auto;
}

.color-primary {
  color: $primary;
}

.color-secondary {
  color: $secondary;
}

.color-negative {
  color: $negative;
}