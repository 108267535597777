@import "../../styles/Colors.scss";

/* ======= Button Base Styles */

button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  display: block;

  &:disabled,
  &[disabled] {
    background: $gray_light;
    color: #efefef;
    pointer-events: none;
    touch-action: none;
    cursor: not-allowed;

    svg * {
      stroke: $gray_light;
    }

    &:hover {
      border: 2px solid $gray_light;
    }
  }
}

a,
a:visited {
  color: $primary;
  transition: color .15s linear;
  text-decoration: none;
  outline: 0;
}

a:hover,
a:active,
a:focus {
  color: $primary;
  text-decoration: underline;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.btn,
button,
input[type="submit"],
input[type="reset"],
input[type="button"],
input[type="file"] {
  outline: 0;
  color: $white;
  background: linear-gradient(135deg, #6DC9C4 0%, #005677 100%);
  background-size: 150% auto;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 20px;
  padding: 16px 24px;
  font-family: 'mazzard-semibold', sans-serif;
  font-weight: normal;
  font-style: normal;
  width: auto;
  border-radius: 50px;
  transition: all .15s linear;
  border: 0;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  line-height: 1em;

  &[class*='btn-primary-outline'] {
    background: $white !important;
    color: $primary;
    box-shadow:inset 0 0 0 2px $gray_light;
  }

  label {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    font-family: inherit;
    gap: 10px;
    cursor: pointer;
  }

  .btn-icon {
    transition: all .15s linear;
    fill: $white;
  }

  svg {
    transition: .15s linear;
  }

  // &:hover {

  //   svg {
  //     transform: translateX(5px);
  //   }
  // }

  &.icon-hov-scale:hover {

    svg {
      transform: scale(1.2);
    }
  }
}

.btn:visited,
button:visited,
input[type="submit"]:visited,
input[type="reset"]:visited,
input[type="button"]:visited {
  color: $white;
}

.btn:hover,
button:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover {
  color: $white;
  background-position: 70% !important;

  .btn-icon {
    fill: $white;
  }

  &[class*='btn-primary-outline'] {
    background: $white !important;
    color: $primary;
    box-shadow:inset 0 0 0 2px $primary;
  }
}

.btn:active,
button:active,
input[type="submit"]:active,
input[type="reset"]:active,
input[type="button"]:active {
  color: $white;
  transform: scale(.95);

  .btn-icon {
    fill: $white;
  }

  &[class*='btn-primary-outline'] {
    background: $white !important;
    color: $primary;
    box-shadow:inset 0 0 0 2px $primary;
  }
}

.btn:focus,
button:focus,
input[type="submit"]:focus,
input[type="reset"]:focus,
input[type="button"]:focus {
  color: $white;
  outline: 0;
  border: 0;
  box-shadow: none;

  .btn-icon {
    fill: $white;
  }

  &[class*='btn-primary-outline'] {
    background: $white !important;
    color: $primary;
    box-shadow:inset 0 0 0 2px $primary;
  }
}

.btn:focus:hover,
button:focus:hover,
input[type="submit"]:focus:hover,
input[type="reset"]:focus:hover,
input[type="button"]:focus:hover {
  color: $white;
  outline: 0;

  .btn-icon {
    fill: $white;
  }

  &[class*='btn-primary-outline'] {
    background: $white !important;
    color: $primary;
    box-shadow:inset 0 0 0 2px $primary;
  }
}
