.auth-wrapper {
  width: 100%;
  max-width: 380px;
  padding: 40px 20px;
  margin: 0 auto;

  .actions {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  h2 {
      text-align: center;
  }

  .auth-form {
    padding: 30px 0;
  }

  .alt-auth {
    margin-top: 40px;
    background: #F4F6F8;
    border-radius: 12px;
    padding: 30px;
    text-align: center;

    a {
      font-family: 'mazzard-bold', sans-serif;
      font-weight: normal;
      font-style: normal;
    }
  }

  .auth-msg {
    margin: 20px 0;
    background: #F4F6F8;
    border-radius: 12px;
    padding: 20px;
    text-align: left;
  }
}

.forgot-password-field {
  margin-top: -15px;
  margin-bottom: 0px !important;
  text-align: right;
}