@import "../../styles/Colors.scss";

video {
  width: 100%;
  display: block;
  background: #000000 !important;
}

.video-wrapper {
  width: 100%;
  max-width: 700px;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
}


.videoControlsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  transition: all 0.3s ease-in-out;
  top: 0;
  left: 0;
}

.videoControlsRow {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 10px;
  position: absolute;
  width: calc(100% - 40px);
  bottom: 20px;
  left: 20px;
  transform: translateY(150%);
  transition: .5s linear;
}

.btnPlayPause {
  height: 52px;
  width: 60px;
  background: rgba(20,20,20,.6);
  border-radius: 8px;
  position: relative;
  margin: 0;

  &:hover {
    background: rgba(20,20,20,.8);
  }
}

.btnPlayPauseIcon {
  height: 42px;
  width: 42px;
  min-width: 42px;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scale(.6);

  svg path {
    fill: $white;
  }

  .iconPause {
    display: none;
  }

  .iconPlay {
    display: block;
  }

 &.pause {
  transform: translateX(-50%) scale(.6);
  
  .iconPause {
    display: block;
  }

  .iconPlay {
    display: none;
  }
 }
}

.videoControlsBar {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  transition: .5s linear;

  .videoTimeAndAction {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .videoTime {
      display: flex;
      align-items: flex-end;
      color: $white;
      font-size: 12px;
      line-height: 1em;
      font-family: 'mazzard-bold', sans-serif;
      font-weight: normal;
      font-style: normal;
      text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
      gap: 5px;
      padding-bottom: 4px;

      .currentTime {
        min-width: 25px;
      }

      .divider {
        opacity: .6;
      }

      .duration {
        opacity: .6;
      }
    }

    .videoActions {
      display: flex;
      flex-direction: row;
      gap: 2px;

      .btnPlaybackSpeed {
        appearance: none;
        background: none;
        color: $white;
        outline: none;
        border: none;
        text-align: center;
        font-family: 'mazzard-bold', sans-serif;
        font-weight: normal;
        font-style: normal;
        font-size: 12px;
        line-height: 24px;
        height: 24px;
        border: 1.5px solid $white;
        border-radius: 4px;
        overflow: hidden;
      }

      .btnMute {
        background: transparent;
        margin: 0;
        padding: 0;
        height: 36px;
        width: 36px;
        transition: .15s linear;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 24px;
        }

        &:hover {
          background: rgba(20,20,20,.6);
        }
      }

      .btnFullscreen {
        background: transparent;
        margin: 0;
        padding: 0;
        height: 36px;
        width: 36px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 24px;
        }

        &:hover {
          background: rgba(20,20,20,.6);

          svg {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}

.btnReinitiatePlayPauseInteractiveArea {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  border-radius: 0;
  transition: .5s linear;
  overflow: hidden;
  opacity: 0;
  cursor: default;

  &:active {
    transform: scale(1);
  }

  .btnReinitiatePlayPause {
    height: 80px;
    width: 80px;
    background: rgba(20,20,20,.6);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    transition: .25s ease;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transform: scale(.8);

    &.iconShown {
      opacity: 1;
      transition: all .15s linear;
      transform: translate(-50%,-50%) scale(1.2);
    }
    
    &.iconHidden {
      opacity: 0;
      transition: all .15s linear .25s;
      transform: translate(-50%,-50%) scale(.8);
    }
  
    .btnReinitiatePlayPauseIcon {
      height: 50px;
      width: 50px;
      min-width: 50px;
      margin: 0;
      padding: 0;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
  
      svg {
        min-width: 50px;
  
        path {
          fill: $white;
        }
      }
    }
  }
}

.btnInitiatePlayInteractiveArea {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  border-radius: 0;
  transition: .5s linear;
  overflow: hidden;
}

.btnInitiatePlay {
  height: 80px;
  width: 80px;
  background: rgba(20,20,20,.6);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  transition: .25s ease;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .btnInitiatePlayIcon {
    height: 50px;
    width: 50px;
    min-width: 50px;
    margin: 0;
    padding: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      min-width: 50px;

      path {
        fill: $white;
      }
    }
  }

  &:hover {
    transform: translate(-50%,-50%) scale(1.1);
  }

  &:active {
    transform: translate(-50%,-50%);
  }
}

.initiatedGradient {
  height: 100px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  border-radius: 0;
  transition: .5s linear;
  overflow: hidden;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,0.8001794467787114) 0%, rgba(0,0,0,0) 100%);
  opacity: 0;
  transition: .5s ease;
}

.initiatedInteractiveArea {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  border-radius: 0;
  transition: .5s linear;
  overflow: hidden;
  
}

.videoProgress {

  .videoProgressTrack {
    border-radius: 20px;
    height: 4px;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    position: relative;
    background: rgba(20,20,20,.6);
    transition: .15s linear;

    .videoProgressTrackIndicator {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      height: 4px;
      width: 100%;
      background: $white;
      position: absolute;
      top: 0;
      left: 3px;
      z-index: 2;

      &:before {
        content: '';
        border-radius: 20px;
        display: block;
        height: 100%;
        width: 4px;
        position: absolute;
        left: -3px;
        background: $white;
      }
    }
  }

  &:hover {

    .videoProgressTrack {
      background: rgba(20,20,20,.8);
    }
  }
}

input[type="range"].videoProgressBar {
  display: block;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  max-width: 100%;
  // height: 4px;
  height: 20px;
  background: rgba(255,0,0,.1);
  cursor: pointer;
  outline: none;
  border-radius: 14px;
  padding: 0;
  margin: 0;
  border: 0 !important;
  box-shadow: none !important;
  background: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}

/* Track: webkit browsers */
input[type="range"].videoProgressBar::-webkit-slider-runnable-track {
  height: 4px;
  background: transparent;
  border-radius: 14px;
}

/* Track: Mozilla Firefox */
input[type="range"].videoProgressBar::-moz-range-track {
  height: 4px;
  background: transparent;
  border-radius: 14px;
}

/* Thumb: webkit */
input[type="range"].videoProgressBar::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 4px;
  width: 4px;
  margin-top: -8px;
  background-color: $white;
  border-radius: 50%;
  transition: opacity .15s linear, transform .15s linear, box-shadow .15s linear;
  opacity: 0;
}

input[type="range"].videoProgressBar:hover::-webkit-slider-thumb {
  border: 2px solid $white;
  box-shadow: 0 0 0 4px rgba(255,255,255,.2);
  transform: scale(2.5);
  opacity: 1;
}

/* Thumb: Firefox */
input[type="range"].videoProgressBar::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 4px;
  width: 4px;
  margin-top: -8px;
  background-color: $white;
  border-radius: 50%;
  transition: opacity .15s linear, transform .15s linear, box-shadow .15s linear;
  opacity: 0;
}

input[type="range"].videoProgressBar:hover::-moz-range-thumb {
  border: 2px solid $white;
  box-shadow: 0 0 0 4px rgba(255,255,255,.2);
  transform: scale(2);
  opacity: 1;
}

.playingInitiated {

  .btnInitiatePlayInteractiveArea {
    opacity: 0;
    touch-action: none;
    pointer-events: none;
  }

  .btnInitiatePlay {
    transform: translate(-50%,-50%) scale(0);
  }

  .initiatedGradient {
    opacity: .5;
  }

  .videoControlsRow {
    transform: translateY(0);
  }

  .btnReinitiatePlayPauseInteractiveArea {
    opacity: 1;
  }
}