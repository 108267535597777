$positive:#00bc81;
$positive_hov:#838582;
$green: #00bc81;

$red: #e1244b;
$negative: $red;
$negative_hov:#E3426E;
$negative_tint:rgba(232,35,86,.05);

$primary: #005677;
$primary_hover: #6eaeb4;
$secondary: #6eaeb4;
$secondary_hov:#318495;

$tint: #F4F6F8;

$green_light: #AFD244;

$white:#ffffff;
$gray_background:#f5f7f8;
$gray_tint:#e4e7e8;
$gray_light:#c8ccd1;
$mediumgray:#727578;
$gray_mediumdark:#6b7178;
$darkgray:#323538;

$gray1: #f2f4f6;
$gray2: #e0e8f0;
$gray3: #b7c4d5;
$gray4: #f2f4f6;
$gray5: #8f99a6; /*"medium gray"*/
$gray6: #f2f4f6;
$gray7: #f2f4f6;
$gray8: #4f565f; /*"dark gray"*/
$gray9: #f2f4f6;
$gray10: #f2f4f6;