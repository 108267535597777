@import "./Colors";

.error-boundary {
  margin: 50px auto 0;
  padding: 0 15px;
  width: 100%;
  max-width: 490px;

  .error-boundary-inner {
    border-radius: 12px;
    overflow: hidden;
    background: #fff;
    box-shadow: inset 0 0 0 1px $gray2, 0 2px 6px 0px rgba(0, 0, 0, 0.05);
    padding: 20px;

    h2 {
      margin-top: 0;
    }

    .btn {
      margin-bottom: 0;
    }
  }
}
