.video-list-header {
  margin-bottom: 20px;
}

.video-list {
  display: flex;
  flex-direction: column;

  .video-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
    border-bottom: 1px solid rgba(0,0,0,.1);
    padding-bottom: 30px;
    margin-bottom: 30px;
    text-decoration: none;

    @media (min-width: 640px) {
      flex-direction: row;
      gap: 30px;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    .video-content {

    }

    .video-thumbnail {
      background: $gray_tint; 
      border-radius: 6px;
      overflow: hidden;
      min-width: 100%;
      transition: .15s linear;
      box-shadow: 0 0 0 2px $white, 0 0 0 2px $white;
      display: flex; 
      align-self: start;
      cursor: pointer;
      @media (min-width: 640px) {
        min-width: 200px;
      }
      
      .btn-play {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background: rgba(10,10,10,.5);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 20;
        transition: .15s linear;
      }

      &:hover {
        box-shadow: 0 0 0 2px $white, 0 0 0 4px $secondary;

        .btn-play {
          background: rgba(10,10,10,.7);
        }
      }

      .video-shape-content {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          border-radius: 4px;
          object-fit: contain;
        }
      }
    }

    .video-overview {

      .note-title {
        font-size: 24px;
        font-family: 'mazzard-bold', sans-serif;
        font-weight: normal;
        font-style: normal;
        color: $darkgray;
      }

      .video-date {
        font-size: 16px;
        color: $mediumgray;
      }

      .video-actions {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        gap: 20px;
        color: $mediumgray;

        .action {
          display: flex;
          flex-direction: row;
          gap: 5px;

          .action-icon {
            width: 16px;

            path,
            circle,
            line,
            polyline,
            polygon {
              fill:none;
              stroke: $mediumgray;
              stroke-width: 1.5px;
              stroke-linecap: round;
              stroke-linejoin: round;
              stroke-miterlimit: 10;
            }
          }
        }

      }

      .video-triggers {
        display: flex;
        gap: 20px;
        justify-content: start;
        align-items: center;
        margin-top: 5px;
        font-family: 'mazzard-semibold', sans-serif;

        .trigger {
          display: flex;
          flex-direction: row;
          gap: 5px;

          svg {
            width: 100%;
            max-width: 18px;

            path {
              fill: $darkgray;
            }
          }

          &:hover {
            color: $primary;
            cursor: pointer;
  
            svg {
              path {
                fill: $primary;
              }
            }
          }
        }
      }
    }
  }
}

.icon-play {

  path {
    fill: $white;
  }
}

.skeleton-my-notes {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background: linear-gradient(-50deg, rgba(255, 255, 255, 0) 25%, $white 40%, rgba(255, 255, 255, 0) 55%);
    animation: skeleton-highlight 4s infinite;
    opacity: .5;
  }

  @keyframes skeleton-highlight {
    0% {
      transform: translateX(-100%);
    }
    50% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }

  .video-list-header {
    background: $gray_tint;
    border-radius: 12px;
    height: 24px;
    width: 160px;
  }

  .video-overview {

    .note-title {
      height: 30px;
      width: 160px;
      background: $gray_tint;
      border-radius: 12px;
    }

    .video-date {
      margin-top: 10px;
      height: 20px;
      width: 100px;
      background: $gray_tint;
      border-radius: 12px;
    }
  }
}

.credits-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  gap: 5px;
  text-align: center;

  .btn {
    margin: 0;
  }

  @media (min-width: 640px) {
    flex-direction: row;
    gap: 10px;
  }

  .credits-count {
    cursor: pointer;

    &:hover {
      color: $primary;
    }
  }

  .buy-credits {
    font-family: 'mazzard-bold', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 12px;
    text-transform: uppercase;
  }
}

.tutorial-container {
  background: $tint;
  border-radius: 12px;
  padding: 30px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 40px;
  text-align: center;

  .btn {
    margin: 0;
  }

  .tutorial-verify-email,
  .tutorial-page {
    width: 100%;
    max-width: 50px;
    margin-bottom: 20px;

    .st0{fill:#FFFFFF;}
    .st1{fill:#E3F3F2;}
    .st2{fill:#6FC9C3;}
    .st3{fill:#015778;}
  }
}

.dummy-add {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;

  .dummy-add-heading {
    color: $darkgray;
    font-size: 22px;
  }
  
  .dummy-add-prompt {
    color: $primary;
    font-family: 'mazzard-bold', sans-serif;
    padding: 2px 12px;
    // border: 2px solid $mediumgray;
    background: $white;
    border-radius: 30px;
  }

}

.infinite-scroll-component__outerdiv {
  width: 100%;

  .infinite-scroll-component {
    overflow: visible !important;
    width: 100%;
  }
}

.share-page {
  display: flex;
  flex-direction:column;
  gap: 10px;
  align-items: center;

  #qrcode-image {
    width: 100%;
    max-width: 250px;
  }

  .copy-url-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 6px;
    padding: 5px;
    width: 100%;
    overflow: hidden;

    &:hover {
      color: $primary;

      svg path {
        fill: $primary;
      }
    }

    .copy-url-icon-title {
      display: flex;
      gap: 5px;
      background:rgba(0,0,0,.1);
      border-radius: 3px;
      padding: 5px; 
    }

    svg {
      width: 20px;
    }

    .copy-url-title {
      font-family: 'mazzard-semibold', sans-serif;
      text-wrap: nowrap;
    }

    .copy-url-full-url {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 5px; 
    }
  }
}

.search-container {
  margin-bottom: 10px;
  position: relative;

  form {
    display: flex;
    flex-direction: column;

    .form-field.search-input {
      width: 100%;
      flex-grow: 1;
      margin-bottom: 0;

      input {
        height: 60px;
        font-size: 24px;
        max-width: 100%;
        padding: 8px 0 8px 55px;
        border-radius: 12px;
        @media (min-width: 640px) {
          font-size: 32px;
        }

        &:hover {
          z-index: 5;
        }
      }
    }

  
    .search-submit {
      position: absolute;
      top: 10px;
      left: 10px;
      height: 40px;
      width: 40px;
      z-index: 10;
      pointer-events: none;
      touch-action: none;

      svg {
        z-index: 11;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
        touch-action: none;
        height: 24px;
        width: 24px;

        circle,
        line {
          fill: none;
          stroke: $mediumgray;
          stroke-width: 2;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 10;
        }
      }

      &.code {

        input {
          background: $tint;
        }

        svg {

          circle,
          line {
            stroke: $primary;
          }
        }

        &:hover {

          input {
            background: $secondary;
          }

          svg {

            circle,
            line {
              stroke: $white;
            }
          }
        }
      }
    }
  }
}

.page-count-status {
  margin-bottom: 40px;
}

.report-top-header {
  display: flex; 
  justify-content: space-between;
  gap: 20px;
  width: 100%;

  .report-title {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;

    @media (min-width: 640px) {
      flex-direction: row;
      align-items: center;
      gap: 20px;
    }

    .page-thumbnail {
      max-width: 120px;
      border-radius: 12px;
      overflow: hidden;
    }

    .page-title-and-date {
      display: flex;
      flex-direction: column;

      .page-title {
        font-size: 24px;
      }

      .page-activated-at {

      }
    }
  }
}

.report-stats {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .report-stat {
    display: flex;
    align-items: center;
    gap: 10px;

    .stat-value {
      font-size: 24px;
      font-family: 'mazzard-bold', sans-serif;
      font-weight: normal;
      font-style: normal;
    }

    .stat-units {
      font-size: 18px;
    }
  }
}

@mixin card-styles {
  padding: 20px;
  border-radius: 12px;
  border: 1px solid $gray_tint;
}

.report-container {
  display: flex;
  flex-direction: column;
  gap: 30px;

  .report-overview {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;

    @media (min-width: 640px) {
      flex-direction: row;
    }

    .report-stats-graphs {
      display: flex;
      flex-direction: column;
      @include card-styles();
    }
  
    .report-graph {
      display: flex;
      flex: 1;
      @include card-styles();
    }
  }

  .report-events {
    display: flex;
    @include card-styles();
  }
}